import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  brandFonts,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
} from '../styles';
import { Button, Container, HtmlContent } from './ui';
import activeFlag from '../images/active-flag.svg';

const StyledTimeline = styled.section``;

const StyledContainer = styled(Container)``;

const StyledInner = styled.div`
  display: flex;
  margin-top: 70px;
  margin-bottom: 250px;

  ${minBreakpointQuery.large`
    margin-left: 152px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 142px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-left: 0;
  `}
`;

const StyledYearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  gap: 10px;

  ${minBreakpointQuery.medium`
    margin-right: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 120px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-right: 120px;
  `}
`;

const StyledYear = styled(Button)`
  background: none;
  color: ${standardColours.black};
  padding: 0;
  text-align: left;
  width: 64px;
  position: relative;
  border: none;
  height: 40px;

  &:hover {
    background: none;
    ${fontSize(26)};
  }

  ${({ display }) => {
    if (display) {
      return css`
        ${fontSize(26)};
        font-family: ${brandFonts.tertiary};
        transition: ${standardTransition('font-size')};

        &:before {
          content: '';
          background: url(${activeFlag}) no-repeat;
          width: 130px;
          height: 90px;
          display: block;
          position: absolute;
          left: -82px;
          top: -32px;

          ${maxBreakpointQuery.large`
            transform: scale(0.3);
          `}

          ${minBreakpointQuery.large`
            left: -152px;
            top: -66px;
          `}

          ${minBreakpointQuery.xxlarge`
            left: -142px;
          `}

          ${minBreakpointQuery.xxxxlarge`
            left: -180px;
          `}
        }
      `;
    } else {
      return css`
        ${fontSize(16)};
      `;
    }
  }}
`;

const StyledContent = styled.div`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  gap: 70px;

  ${minBreakpointQuery.large`
   margin-right: 112px;
  `}

  ${minBreakpointQuery.xxlarge`
    flex-direction: row;
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  white-space: break-spaces;
  b {
    font-family: ${brandFonts.tertiary};
    ${fontSize(22)}

    ${minBreakpointQuery.small`
      ${fontSize(24)}
    `}
    
    ${minBreakpointQuery.medium`
      ${fontSize(26)}
    `}

    ${minBreakpointQuery.large`
      ${fontSize(28)}
    `}
  }
`;

const StyledImageWrapper = styled.div`
  flex-shrink: 0;
`;

const StyledImage = styled(GatsbyImage)``;

const StyledCaption = styled.p`
  font-weight: ${fontWeights.bold};
  max-width: 516px;
  margin-top: 18px;
  ${fontSize(16)}
`;

const Timeline = ({ items }) => {
  const [activeItem, setActiveItem] = useState(0);
  return (
    <StyledTimeline>
      <StyledContainer>
        <StyledInner>
          <StyledYearWrapper>
            {items.map(({ year }, i) => {
              const display = activeItem === i;
              return (
                <StyledYear
                  onClick={() => setActiveItem(display ? undefined : i)}
                  display={display}
                  key={i}
                >
                  {year}
                </StyledYear>
              );
            })}
          </StyledYearWrapper>
          {items.map(
            ({ image: { gatsbyImageData, description, title }, text }, i) => {
              const display = activeItem === i;
              return (
                <StyledContent display={display} key={i}>
                  <StyledHtmlContent richText={text} />
                  <StyledImageWrapper>
                    <StyledImage
                      image={gatsbyImageData}
                      alt={title}
                      title={title}
                    />
                    {description && (
                      <StyledCaption>{description}</StyledCaption>
                    )}
                  </StyledImageWrapper>
                </StyledContent>
              );
            }
          )}
        </StyledInner>
      </StyledContainer>
    </StyledTimeline>
  );
};

export default Timeline;
