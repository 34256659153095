import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Button, Container, HtmlContent, Svg } from './ui';
import Breadcrumbs from './Breadcrumbs';
import backgroundFlagTwo from '../images/background-flag-2.inline.svg';
import arrowBottom from '../images/arrow-bottom.svg';

const StyledAboutBanner = styled.div`
  position: relative;

  ${maxBreakpointQuery.large`
    overflow: hidden;
  `}
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 30px;

  ${minBreakpointQuery.xlarge`
    margin-left: 54px;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  ${minBreakpointQuery.xxlarge`
    flex-direction: row;
  `}
`;

const StyledTextWrapper = styled.div`
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 100px;
  position: relative;

  ${maxBreakpointQuery.xxlarge`
    padding-right: 30px;
    padding-left: 30px;
  `}

  ${minBreakpointQuery.tiny`
    margin-bottom: 0;
  `}

  ${minBreakpointQuery.large`
    margin-top: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 0;
    margin-right: 60px;
    margin-bottom: 130px;
  `}

  ${minBreakpointQuery.xxxlarge`
    margin-bottom: 90px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-left: 60px;
    margin-bottom: 90px;
  `}
`;

const StyledHeading = styled.h1`
  color: ${brandColours.septenary};
  font-family: ${brandFonts.tertiary};
  margin-bottom: 40px;

  ${fluidFontSize(
    '18px',
    '50px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.small`
    max-width: 100%;
  `}

  ${minBreakpointQuery.xxlarge`
    max-width: 830px; 
    margin-bottom: 20px;
   `}

  ${minBreakpointQuery.xxxlarge`
    margin-bottom: 40px;
   `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  color: ${brandColours.septenary};

  p {
    ${fontSize(16)}
    ${minBreakpointQuery.small`
      ${fontSize(18)}
    `}

    ${minBreakpointQuery.medium`
      ${fontSize(20)}
    `}
   
    ${minBreakpointQuery.large`
      ${fontSize(22)}
    `}

    ${minBreakpointQuery.xxlarge`
      max-width: 640px; 
    `}
  }
`;

const StyledButton = styled(Button)`
  scale: 0.7;
  padding: 29px 38px;
  border-radius: 50%;
  border: solid 2px ${standardColours.black};
  background-color: ${standardColours.white};
  margin-top: 20px;
  z-index: ${zIndexLayers.first};

  &:before {
    content: '';
    display: block;
    background: url(${arrowBottom}) no-repeat;
    height: 36px;
    width: 18px;
  }

  ${minBreakpointQuery.medium`  
    margin-left: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 0;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-bottom: 0;
    margin-top: 30px;
  `}

  ${minBreakpointQuery.xxlarge`
    position: absolute;
    bottom: -65px;
    right: -10px;
  `}

  ${minBreakpointQuery.xxxlarge`
    position: relative;
    bottom: 0;
    right: 0;
    scale: 1;
    margin-top: 10px;
    margin-left: 84px;
  `}
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  pointer-events: none;
`;

const StyledBackgroundFlagTwo = styled(Svg)`
  position: absolute;
  height: auto;
  top: -30px;
  right: -30px;
  width: 120%;

  ${minBreakpointQuery.xlarge`
    right:-40px;
  `}
`;

const AboutBanner = ({ title, items: { heading, image, text } }) => {
  const handleClickScroll = target => {
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <StyledAboutBanner>
      <StyledContainer wide={true}>
        <StyledBreadcrumbs currentPageTitle={title} />
        <StyledInner>
          <StyledTextWrapper>
            <StyledHeading>{heading}</StyledHeading>
            <StyledHtmlContent richText={text} />
            <StyledButton onClick={() => handleClickScroll('About')} />
          </StyledTextWrapper>
          <StyledImageWrapper>
            <StyledImage
              image={image.gatsbyImageData}
              alt={image.title}
              objectFit={'contain'}
            />
            <StyledBackgroundFlagTwo
              image={backgroundFlagTwo}
              alt={'Background Flag'}
            />
          </StyledImageWrapper>
        </StyledInner>
      </StyledContainer>
    </StyledAboutBanner>
  );
};

export default AboutBanner;
