import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import AboutBanner from '../components/AboutBanner';
import OurHistory from '../components/OurHistory';

const AboutPage = ({
  data: {
    contentfulAbout: {
      title,
      banner,
      history: {
        heading,
        text: { text },
        timeline,
      },
    },
  },
}) => {
  return (
    <Layout>
      <main>
        <AboutBanner title={title} items={banner} />
        <OurHistory heading={heading} text={text} timeline={timeline} />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    contentfulAbout {
      title
      metaDescription
      slug
      banner {
        heading
        image {
          gatsbyImageData(width: 1076, height: 1196, quality: 100)
          title
        }
        text {
          raw
        }
      }
      history {
        heading
        text {
          text
        }
        timeline {
          image {
            gatsbyImageData(width: 516, height: 354)
            description
            title
          }
          text {
            raw
          }
          year
        }
      }
    }
  }
`;

export default AboutPage;

export const Head = ({
  data: {
    contentfulAbout: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
